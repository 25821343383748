<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <form @submit.prevent="submit" class="form-horizontal">

          <div class="form-body">
            <h3 class="box-title">Surat Selesai</h3>
            <hr class="m-t-0 m-b-40">

            <div class="row">
              <div class="col-md-8">

                <div class="form-group row">
                    <label for="error" class="control-label text-right col-md-3">
                        Surat Selesai
                        <small style="color: red;">*</small>
                    </label>
                    
                    <div class="col-md-9 col-xs-9">
                        <input 
                          type="radio" 
                          checked="checked"
                          id="selesai" 
                          name="selesai" 
                          value="1" 
                          v-model="payload.selesai"
                        />
                        <label for="selesai">Selesai</label>&nbsp;
                    </div>

                </div>

              </div>
            </div>
            <br/>

            <div class="row">
              <div class="col-md-12">
                <wysiwyg v-model="myHTML"/>
              </div>
            </div>

          </div>
          <hr>
          <div class="form-actions">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-offset-3 col-md-9">
                    <button type="submit" class="btn btn-success" title="Submit">Submit</button>&nbsp;
                    <button type="button" @click="goBack()" class="btn btn-inverse" title="Cancel">Cancel</button>
                  </div>
                </div>
              </div>
              <div class="col-md-6"></div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Input,
    RotateSquare5,
    Upload,
    Multiselect
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.documentIn;
    },
    isInvalidBahasa() {
      return this.isTouched && this.payload.valueBahasa.length === 0;
    },
    isInvalidUnit() {
      return this.isTouched && this.payload.valueUnit.length === 0;
    },
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  data() {
    return {
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable"
      ],
      payload: {
        selesai: 1,
        myHtml: ""
      },
      myHTML: '',
      isTouched: false,
      isDisabled: false
    };
  },
  async mounted() {
    this.initialize();
  },

  methods: {
    async initialize() {
      try {
        if (this.editMode) {
          axios.get(`/document_in/${this.$route.params.id}`).then(response => {
            const state = {
              loaded: true
            };
            this.$store.commit("documentIn/STATE", state);
            this.edit(response.data.data);
          });
        } else {
          this.$store.dispatch("documentIn/onAdd");
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      // let conf = data.selesai==""?1:data.selesai;
      // this.myHTML = data.confirmation;
      // this.payload = {
      //   close: conf,
      //   myHTML: data.confirmation,
      // };
    },
    onTouch() {
      this.isTouched = true;
    },
    onChangeBahasa(value) {
      this.payload.valueBahasa = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueBahasa = [];
    },
    onChangeUnit(value) {
      this.payload.valueUnit = value;
      if (value.indexOf("Reset me!") !== -1) this.payload.valueUnit = [];
    },
    onSelect(option) {
      if (option === "Disable me!") this.isDisabled = true;
    },
    goBack() {
      this.$store.dispatch("documentIn/onCancel");
    },
    submit() {
      const payload = {
        close: this.payload.selesai,
        description: this.myHTML,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then(success => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("documentIn/submitClose", {data, id: this.$route.params.id});
          } else {
            this.$store.dispatch("documentIn/submitClose", {data, id: this.$route.params.id});
          }
        }
      });
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
</style>

